
$primcolor: #39324B;
$seccolor: #6C4F70;
$thirdcolor: #6B82A8;
$forthcolor: #757081;
$lightcolor: #D1D3CF;

@font-face{ 
	font-family: 'Diner';
	src: url('../web_fonts/diner-regular_regular_macroman/Diner-Regular-webfont.eot');
	src: url('../web_fonts/diner-regular_regular_macroman/Diner-Regular-webfont.eot?iefix') format('eot'),
	     url('../web_fonts/diner-regular_regular_macroman/Diner-Regular-webfont.ttf') format('truetype'),
	     url('../web_fonts/diner-regular_regular_macroman/Diner-Regular-webfont.svg') format('svg');
}

*{	
	font-family: Diner;
	&:focus {
    outline: none;
}
}
body{
	background-color: $lightcolor;
	font-size:12pt;
	letter-spacing:5px;
}
h1, h2, h3, h4{
	margin-top:40pt;
}
.logo{
	width:150px;
}
.center{
	text-align:center;
}
.header{
	text-align:center;
	margin-top:20pt;
	margin-bottom:40pt;
}
.content{
	& .container{
		margin:auto;
		max-width:1000px;
	}
	& .button-group{
		text-align:center;
	}
}
button{
	transition: all 0.2s;
	background-color:$lightcolor;
	color: $primcolor;
	border: 2px solid $seccolor;
	border-radius: 7px;
	padding:5pt 7pt 3pt 10pt;
	margin:5pt;
	cursor:pointer;
	font-size:16pt;
	font-weight: bold;
	line-height: 16pt;
	letter-spacing:5px;
	height:30pt;
	&:hover{
		background-color: $lightcolor - #222222;
	}
	&:active{
		background-color: $thirdcolor;
	}

	&.active{
		background-color:$forthcolor;
		color:$lightcolor;
	}
}
.flex{
	display:flex;
	justify-content:center;
	
	& span{
		
		margin:10pt;
		&.active{
			font-weight:bold;
			text-decoration:underline;
		}
	}
}
label{
	line-height:10pt;
	display:inline-block;
	& span{
		top: 10pt;
		display:block;
	}
	& input[type=range]{
		display:block;
		margin:auto;
		width:20pt;
		appearance: slider-vertical;
	}
}